import ApiRequestError from '../utils/errors/ApiRequestError';
import ResponseFailedError from '../utils/errors/ResponseFailedError';
import { Getters as ApiGetters } from './apiStore';

export const Actions = {
  retrieveUserInvitations: "retrieveUserInvitations",
  createUserInvitation: "createUserInvitation",
  deleteUserInvitation: "deleteUserInvitation",
  sendEmail: "sendEmail",
};

// Create a new store instance.
const userInvitationsModule = {
  actions: {
    async [Actions.retrieveUserInvitations](context, filters = {}) {
      try {
        const axiosResponse = await context.getters[ApiGetters.userInvitationController].apiUsersInvitationsGet(filters.pageSize, filters.pageNumber, filters.sortBy, filters.sortOrder);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const userInvitations = response.data;

          return userInvitations;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.createUserInvitation](context, userInvitation) {
      try {
        const axiosResult = await context.getters[ApiGetters.userInvitationController].apiUsersInvitationsPost(
          userInvitation
        );

        const response = axiosResult.data;
        if (response.status === "success") {
          return response.data;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.deleteUserInvitation](context, userInvitationId) {
      try {
        const axiosResponse = await context.getters[ApiGetters.userInvitationController].apiUsersInvitationsIdDelete(userInvitationId);
        
        const response = axiosResponse.data;
        if (response.status == "success") {
          const userInvitation = response.data;

          return userInvitation;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.sendEmail](context, userInvitationId) {
      try {
        const axiosResponse = await context.getters[ApiGetters.userInvitationController].apiUsersInvitationsIdEmailPost(userInvitationId);
        
        const response = axiosResponse.data;
        if (response.status == "success") {
          const userInvitation = response.data;

          return userInvitation;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
  }
};

export default userInvitationsModule;