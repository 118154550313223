import ApiRequestError from '../utils/errors/ApiRequestError';
import { Getters as ApiGetters } from './apiStore';

export const Actions = {
  generateEventUsers: "generateEventUsers",
  generateUsers: "generateUsers",
};

// Create a new store instance.
const excelModule = {
  actions: {
    async [Actions.generateEventUsers](context) {
      try {
        const axiosResponse = await context.getters[ApiGetters.excelController].apiExcelsEventUserGet({ responseType: "blob" });
        
        const response = axiosResponse.data;

        const mimeType = axiosResponse.headers["content-type"];
        
        return new File([response], "Event Users.xlsx", { type: mimeType });
      } catch (e) {
        throw new ApiRequestError(e);
      }
    },
    async [Actions.generateUsers](context) {
      try {
        const axiosResponse = await context.getters[ApiGetters.excelController].apiExcelsUserGet({ responseType: "blob" });
        
        const response = axiosResponse.data;

        const mimeType = axiosResponse.headers["content-type"];
        
        return new File([response], "Users.xlsx", { type: mimeType });
      } catch (e) {
        throw new ApiRequestError(e);
      }
    },
  }
};

export default excelModule;