import Vue from "vue";
import Vuex from "vuex";

import apiStore from "./apiStore";
import authStore, { Actions as AuthActions } from "./authStore";
import equipmentStore, { Actions as EquipmentActions } from "./equipmentStore";
import eventsStore, { Actions as EventsActions } from './eventsStore';
import eventUsersStore, { Actions as EventUsersActions } from './eventUsersStore';
import excelStore, { Actions as ExcelActions } from './excelStore';
import locationsStore, { Actions as LocationsActions } from './locationsStore';
import instrumentsStore, { Actions as InstrumentsActions } from './instrumentsStore';
import notificationsStore, { Actions as NotificationsActions } from './notificationsStore';
import globalStore, { Actions as GlobalActions } from './globalStore';
import storageStore, { Actions as StorageActions, Getters as StorageGetters } from './storageStore';
import usersStore, { Actions as UsersActions } from './usersStore';
import viewModelsStore, { Actions as ViewModelsActions } from './viewModelsStore';
import userInvitationsStore, { Actions as UserInvitationsActions} from './userInvitationsStore';

Vue.use(Vuex);

export const Actions = {
  auth: AuthActions,
  equipment: EquipmentActions,
  events: EventsActions,
  eventUsers: EventUsersActions,
  excel: ExcelActions,
  locations: LocationsActions,
  instruments: InstrumentsActions,
  notifications: NotificationsActions,
  global: GlobalActions,
  storage: StorageActions,
  user: UsersActions,
  userInvitations: UserInvitationsActions,
  viewModels: ViewModelsActions,
};
export { AuthActions, EquipmentActions, EventsActions, EventUsersActions, ExcelActions, LocationsActions, NotificationsActions, InstrumentsActions, GlobalActions, UsersActions, UserInvitationsActions, ViewModelsActions };

export const Getters = {
  storage: StorageGetters,
};

const store = new Vuex.Store({
  modules: {
    api: apiStore,
    auth: authStore,
    equipment: equipmentStore,
    events: eventsStore,
    eventUsers: eventUsersStore,
    excel: excelStore,
    locations: locationsStore,
    instruments: instrumentsStore,
    notifications: notificationsStore,
    global: globalStore,
    storage: storageStore,
    users: usersStore,
    userInvitations: userInvitationsStore,
    viewModels: viewModelsStore,
  }
});

export default store;