import axios from "axios";

import alertService, { MessageType } from '@/services/AlertService';
import store, { Actions } from '@/stores/store';

/**
 * Used to manage the errors thrown by the axios requests
 */
export default class ApiRequestError extends Error {
  constructor(e, responseCallback) {
    if (axios.isAxiosError(e)) {
      if (e.response) {
        const response = e.response.data;

        if (responseCallback) {
          super(responseCallback(response));
        } else if (response.status == "fail") {
          super(response.message);
        } else {
          super("Internal server error");
        }
        
        if (e.response.status === 401) {
          store.dispatch(Actions.global.logout)
          .finally(() => {
            location.reload();
          });
        }
      } else {
        super("The server is not reacheable");
      }
    } else {
      console.error(e);
      super("Request error");
    }

    alertService.showAlert("Error", this.message, MessageType.danger);
  }
}