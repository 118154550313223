import Vue from "vue";
import VueRouter from "vue-router";

import store, { Actions, Getters } from '@/stores/store';
import routesNames from './routesNames';

Vue.use(VueRouter);
Vue.prototype.$routes = routesNames;

const routes = [
  {
    ...routesNames.homePage,
    component: () => import('../views/HomePage.vue'),
    beforeEnter: async (to, from, next) => {
      const user = store.getters[Getters.storage.user];

      if (!user) {
        next({ name: routesNames.login.name, replace: true });
        return;
      }

      next();
    },
    meta: { authRequired: true },
  },
  {
    ...routesNames.login,
    component: () => import('../views/Login.vue'),
    meta: { authRequest: true },
  },
  {
    ...routesNames.forgotPassword,
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    ...routesNames.resetPassword,
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    ...routesNames.usersInvitations.list,
    component: () => import('../views/usersInvitations/List.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.usersInvitations.form,
    component: () => import('../views/usersInvitations/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.users.list,
    component: () => import('../views/users/List.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.users.details,
    component: () => import('../views/users/Details.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.users.create,
    component: () => import('../views/users/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.users.update,
    component: () => import('../views/users/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.locations.list,
    component: () => import('../views/locations/List.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.locations.create,
    component: () => import('../views/locations/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.locations.update,
    component: () => import('../views/locations/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.locations.details,
    component: () => import('../views/locations/Details.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.events.list,
    component: () => import('../views/events/List.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.events.create,
    component: () => import('../views/events/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.events.update,
    component: () => import('../views/events/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.events.details,
    component: () => import('../views/events/Details.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.eventUsers.list,
    component: () => import('../views/eventUsers/List.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.eventUsers.create,
    component: () => import('../views/eventUsers/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.instruments.list,
    component: () => import('../views/instruments/List.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.instruments.create,
    component: () => import('../views/instruments/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.instruments.update,
    component: () => import('../views/instruments/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.instruments.details,
    component: () => import('../views/instruments/Details.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.equipment.list,
    component: () => import('../views/equipment/List.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.equipment.create,
    component: () => import('../views/equipment/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.equipment.update,
    component: () => import('../views/equipment/Form.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.notifications.send,
    component: () => import('../views/notifications/Notifications.vue'),
    meta: { authRequired: true },
  },
  {
    ...routesNames.pageNotFound.home,
    component: () => import('../views/PageNotFound.vue'),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});


router.beforeEach(async (to, from, next) => {
  let user = await store.getters[Getters.storage.user];
  if (!user) {
    await store.dispatch(Actions.global.syncStores);
    user = await store.getters[Getters.storage.user];
  }

  const isAuthorizationRequest = to.meta.authRequest ?? false;
  const isAuthorizationRequired = to.meta.authRequired ?? false;

  if (user && isAuthorizationRequest) {
    next({ name: routesNames.homePage.name, replace: true });
    return;
  } else if (user == null && isAuthorizationRequired) {
    next({ name: routesNames.login.name, replace: true });
    return;
  }

  next();
});

export default router;