import { Actions as ApiActions } from './apiStore';
import { Actions as AuthActions } from './authStore';
import { Actions as StorageActions } from './storageStore';

export const Actions = {
  login: "loginFacade",
  logout: "logout",
  syncStores: "syncStores",
};

// Create a new store instance.
const globalModule = {
  state() {
    return {
      user: null
    }
  },
  actions: {
    async [Actions.login](context, form) {
      try {
        const user = await context.dispatch(AuthActions.login, form);

        await context.dispatch(StorageActions.setUser, user);
        await context.dispatch(StorageActions.setAccessToken, user.jwtToken);

        await context.dispatch(ApiActions.initApiControllers);

        return user;
      } catch (err) {
        throw new Error(err);
      }
    },
    async [Actions.logout]({ dispatch }) {
      await dispatch(StorageActions.removeUser);
      await dispatch(StorageActions.removeAccessToken);
      await dispatch(ApiActions.resetApiControllers);
    },
    async [Actions.syncStores]({ dispatch }) {
      if (await dispatch(StorageActions.syncStorage)) {
        await dispatch(ApiActions.initApiControllers);
      } else {
        await dispatch(Actions.logout);
      }
    }
  }
};

export default globalModule;