import { Configuration, EquipmentControllerApiFactory, EventControllerApiFactory, EventLocationControllerApiFactory, EventUserControllerApiFactory, ExcelControllerApiFactory, InstrumentControllerApiFactory, NotificationControllerApiFactory, UserControllerApiFactory, UserInvitationControllerApiFactory, ViewModelControllerApiFactory } from '../../libs/dist/bandaapp-openapi';
import { Getters as StorageGetters } from './storageStore';

const Mutations = {
  setControllers: "setControllers",
}

export const Actions = {
  initApiControllers: "initApiControllers",
  resetApiControllers: "resetApiControllers",
};

export const Getters = {
  equipmentController: "equipmentController",
  eventController: "eventController",
  eventLocationController: "eventLocationController",
  eventUserController: "eventUserController",
  excelController: "excelController",
  instrumentController: "instrumentController",
  notificationController: "notificationController",
  userController: "userController",
  userInvitationController: "userInvitationController",
  viewModelController: "viewModelController",
};

// Create a new store instance.
const apiModule = {
  state() {
    return {
      apiControllers: {},
      configuration: null,
    };
  },
  mutations: {
    [Mutations.setControllers](state, controllers) {
      state.apiControllers = controllers;
    },
  },
  actions: {
    async [Actions.initApiControllers](context) {
      const accessToken = context.getters[StorageGetters.accessToken];

      const configuration = new Configuration({ accessToken });

      const controllers = {
        [Getters.equipmentController]: EquipmentControllerApiFactory(configuration),
        [Getters.eventController]: EventControllerApiFactory(configuration),
        [Getters.eventLocationController]: EventLocationControllerApiFactory(configuration),
        [Getters.eventUserController]: EventUserControllerApiFactory(configuration),
        [Getters.excelController]: ExcelControllerApiFactory(configuration),
        [Getters.instrumentController]: InstrumentControllerApiFactory(configuration),
        [Getters.notificationController]: NotificationControllerApiFactory(configuration),
        [Getters.userController]: UserControllerApiFactory(configuration),
        [Getters.userInvitationController]: UserInvitationControllerApiFactory(configuration),
        [Getters.viewModelController]: ViewModelControllerApiFactory(configuration),
      };
      
      context.commit(Mutations.setControllers, controllers);
    },
    async [Actions.resetApiControllers](context) {
      context.commit(Mutations.setControllers, {});
    },
  },
  getters: {
    [Getters.equipmentController](state) {
      return state.apiControllers[Getters.equipmentController];
    },
    [Getters.eventController](state) {
      return state.apiControllers[Getters.eventController];
    },
    [Getters.eventLocationController](state) {
      return state.apiControllers[Getters.eventLocationController];
    },
    [Getters.eventUserController](state) {
      return state.apiControllers[Getters.eventUserController];
    },
    [Getters.excelController](state) {
      return state.apiControllers[Getters.excelController];
    },
    [Getters.instrumentController](state) {
      return state.apiControllers[Getters.instrumentController];
    },
    [Getters.notificationController](state) {
      return state.apiControllers[Getters.notificationController];
    },
    [Getters.userController](state) {
      return state.apiControllers[Getters.userController];
    },
    [Getters.userInvitationController](state) {
      return state.apiControllers[Getters.userInvitationController];
    },
    [Getters.viewModelController](state) {
      return state.apiControllers[Getters.viewModelController];
    },
  }
};

export default apiModule;