import ApiRequestError from '../utils/errors/ApiRequestError';
import ResponseFailedError from '../utils/errors/ResponseFailedError';
import { Getters as ApiGetters } from './apiStore';

export const Actions = {
  retrieveUsers: "retrieveUsers",
  retrieveUser: "retrieveUser",
  createUser: "createUser",
  updateUser: "updateUser",
  updateInstrument: "updateUserInstrument",
  deleteUser: "deleteUser",
};

// Create a new store instance.
const usersModule = {
  state () {
    return {
      user: null
    }
  },
  actions: {
    async [Actions.retrieveUsers](context, filters = {}) {
      try {
        const axiosResponse = await context.getters[ApiGetters.userController].apiUsersGet(
          filters.pageSize, 
          filters.pageNumber, 
          filters.sortBy, 
          filters.sortOrder, 
          filters.withInstruments,
          filters.eventsIds,
          filters.withEventUser,
          filters.attendanceStatuses,
        );

        const response = axiosResponse.data;
        if (response.status == "success") {
          const users = response.data;

          return users;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.createUser](context, user) {
      try {
        const axiosResult = await context.getters[ApiGetters.userController].apiUsersPost(
          user
        );

        const response = axiosResult.data;
        if (response.status === "success") {
          return response.data;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.retrieveUser](context, userId) {
      try {
        const axiosResponse = await context.getters[ApiGetters.userController].apiUsersIdGet(userId);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const user = response.data;

          return user;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.updateUser](context, user) {
      if (!user.id) {
        throw new Error("The user id is undefined");
      }

      try {
        const axiosResult = await context.getters[ApiGetters.userController].apiUsersIdPut(
          user.id,
          user
        );

        const response = axiosResult.data;
        if (response.status === "success") {
          return response.data;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.updateInstrument](context, userInstrumentsPut) {
      try {
        const userId = userInstrumentsPut.userId;

        const axiosResponse = await context.getters[ApiGetters.userController].apiUsersIdInstrumentsPut(userId, userInstrumentsPut);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const user = response.data;

          return user;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.deleteUser](context, userId) {
      try {
        const axiosResponse = await context.getters[ApiGetters.userController].apiUsersIdDelete(userId);
        
        const response = axiosResponse.data;
        if (response.status == "success") {
          const user = response.data;

          return user;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
  }
};

export default usersModule;