<template>
  <div id="app">
    <NavBar v-if="isLoggedIn" />
    <Alert :alertService="alertService" />
    <loading-spinner
      v-if="loadingSpinnerService.isLoading"
      :fullScreen="true"
    />
    <router-view class="p-4"></router-view>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Alert from "prettyneat-vue/src/components/Alert.vue";
import LoadingSpinner from "prettyneat-vue/src/components/LoadingSpinner.vue";

import AlertService from "@/services/AlertService";
import { Instance } from "./services/LoadingSpinnerService";
import { Getters } from "@/stores/store";

export default {
  name: "App",
  components: {
    Alert,
    LoadingSpinner,
    NavBar,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters[Getters.storage.user] != null;
    },
  },
  created() {
    document.title = "Mgarr Band Admin";
  },
  data() {
    return {
      loadingSpinnerService: Instance,
      alertService: AlertService,
    };
  },
};
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css";
html,
body {
  height: 100%;
}
body {
  background-color: #f7f9fb;
  color: #2c3e50;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
