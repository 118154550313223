export const StorageKey = {
  accessToken: "accessToken",
  user: "user"
}

export default {
  get(key) {
    return new Promise((resolve, reject) => {
      try {
        const value = localStorage.getItem(key);
        resolve(value);
      } catch (e) {
        reject(e);
      }
    });
  },
  set(key, value) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(key, value);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },
  remove(key) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(key);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
};