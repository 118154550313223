import { AuthControllerApiFactory } from '../../libs/dist/bandaapp-openapi';
import ApiRequestError from '../utils/errors/ApiRequestError';
import ResponseFailedError from '../utils/errors/ResponseFailedError';

export const Actions = {
  login: "login",
  sendResetPasswordRequest: "sendResetPasswordRequest",
  retrieveUserByResetPasswordCode: "retrieveUserByResetPasswordCode",
  resetPassword: "resetPassword",
};

// Create a new store instance.
const authModule = {
  actions: {
    async [Actions.login](context, form) {
      try {
        const axiosResponse = (await AuthControllerApiFactory().apiAuthLoginPost(form));

        const response = axiosResponse.data;
        if (response.status == "success") {
          return response.data;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.sendResetPasswordRequest](context, email) {
      try {
        const axiosResponse = (await AuthControllerApiFactory().apiAuthGetresetPost({ email }));

        const response = axiosResponse.data;
        if (response.status == "success") {
          return response.data;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.retrieveUserByResetPasswordCode](context, code) {
      try {
        const axiosResponse = (await AuthControllerApiFactory().apiAuthCheckresetCodeGet(code))

        const response = axiosResponse.data;
        if (response.status == "success") {
          return response.data;
        } else {
          throw new ResponseFailedError(response.message);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw new Error(e.message);
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.resetPassword](context, { code, newPassword }) {
      try {
        const axiosResponse = (await AuthControllerApiFactory().apiAuthDoresetPost({ code, newPassword }))

        const response = axiosResponse.data;
        if (response.status == "success") {
          return response.data;
        } else {
          throw new ResponseFailedError(response.message);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw new Error(e.message);
        } else {
          throw new ApiRequestError(e);
        }
      }
    }
  }
};

export default authModule;