import ApiRequestError from '../utils/errors/ApiRequestError';
import ResponseFailedError from '../utils/errors/ResponseFailedError';
import { Getters as ApiGetters } from './apiStore';

export const Actions = {
  retrieveEventsViewModels: "retrieveEventsViewModels",
};

// Create a new store instance.
const eventsModule = {
  actions: {
    async [Actions.retrieveEventsViewModels](context, filters = {}) {
      try {
        const axiosResponse = await context.getters[ApiGetters.viewModelController].apiViewModelsEventsGet(
          filters.userId,
          filters.publicOnly
        );

        const response = axiosResponse.data;
        if (response.status == "success") {
          const events = response.data;

          return events;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
  },
};

export default eventsModule;