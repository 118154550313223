import ApiRequestError from '../utils/errors/ApiRequestError';
import ResponseFailedError from '../utils/errors/ResponseFailedError';
import { Getters as ApiGetters } from './apiStore';

export const Actions = {
  sendNotifications: "sendNotifications",
};

// Create a new store instance.
const notificationsModule = {
  actions: {
    async [Actions.sendNotifications](context, notificationsPost) {
      try {
        const axiosResponse = await context.getters[ApiGetters.notificationController].apiNotificationsPost(notificationsPost);
        
        const response = axiosResponse.data;
        if (response.status == "success") {
          const notification = response.data;

          return notification;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
  }
};

export default notificationsModule;