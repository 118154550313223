"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Banda App API
 * The API for the Banda App
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationAction = void 0;
/**
 *
 * @export
 * @enum {string}
 */
exports.NotificationAction = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
};
