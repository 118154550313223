export default class LoadingSpinnerService {
  _isLoading;

  get isLoading() {
    return this._isLoading;
  }

  constructor() {
    this._isLoading = false;
  }

  toggle(isLoading = undefined) {
    if (isLoading) {
      this._isLoading = isLoading;
    } else {
      this._isLoading = !this.isLoading;
    }
  }
}

export const Instance = new LoadingSpinnerService();