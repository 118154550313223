import StorageUtils, { StorageKey } from "@/utils/StorageUtils";

const Mutations = {
  setAccessToken: "setAccessToken",
  setUser: "setUser",
};

export const Actions = {
  syncStorage: "syncStorage", // sync store's state with Storage (on reload)
  setAccessToken: "setAccessToken",
  removeAccessToken: "removeAccessToken",
  setUser: "setUser",
  removeUser: "removeUser",
};

export const Getters = {
  accessToken: "accessToken",
  pushNotificationsToken: "pushNotificationsToken",
  user: "user",
};

// Create a new store instance.
const accessTokenModule = {
  state () {
    return {
      accessToken: null,
      user: null,
    };
  },
  mutations: {
    [Mutations.setAccessToken](state, accessToken) {
      state.accessToken = accessToken;
    },
    [Mutations.setUser](state, user) {
      state.user = user;
    },
  },
  actions: {
    async [Actions.syncStorage]({ commit, dispatch }) {
      const accessToken = await StorageUtils.get(StorageKey.accessToken);
      const userJson = await StorageUtils.get(StorageKey.user);

      if (accessToken != null && userJson != null) {
        try {
          const user = JSON.parse(userJson);
          commit(Mutations.setUser, user);
        } catch (e) {
          console.error(e);
          await dispatch(Actions.removeAccessToken);
          await dispatch(Actions.removeUser);

          return false;
        }

        commit(Mutations.setAccessToken, accessToken);

        return true;
      } else {
        return false;
      }
    },
    async [Actions.setAccessToken]({ commit }, accessToken) {
      await StorageUtils.set(StorageKey.accessToken, accessToken);
      commit(Mutations.setAccessToken, accessToken);
    },
    async [Actions.removeAccessToken]({ commit }) {
      await StorageUtils.remove(StorageKey.accessToken);
      commit(Mutations.setAccessToken, null);
    },
    async [Actions.setUser]({ commit }, user) {
      commit(Mutations.setUser, user);
      await StorageUtils.set(StorageKey.user, JSON.stringify(user));
    },
    async [Actions.removeUser]({ commit }) {
      commit(Mutations.setUser, null);
      await StorageUtils.remove(StorageKey.user);
    },
  },
  getters: {
    [Getters.accessToken](state) {
      return state.accessToken;
    },
    [Getters.user](state) {
      return state.user;
    },
  }
};

export default accessTokenModule;