import ApiRequestError from '../utils/errors/ApiRequestError';
import ResponseFailedError from '../utils/errors/ResponseFailedError';
import { Getters as ApiGetters } from './apiStore';

export const Actions = {
  retrieveLocations: "retrieveLocations",
  retrieveLocation: "retrieveLocation",
  createLocation: "createLocation",
  updateLocation: "updateLocation",
  updateLocationImage: "updateLocationImage",
  updateLocationOpeningHours: "updateLocationOpeningHours",
  deleteLocation: "deleteLocation",
};

// Create a new store instance.
const eventsModule = {
  actions: {
    async [Actions.retrieveLocations](context, filters = {}) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventLocationController].apiEventLocationsGet(
          filters.onlyPlaceOfInterest,
          filters.pageSize,
          filters.pageNumber,
          filters.sortBy,
          filters.sortOrder
        );

        const response = axiosResponse.data;
        if (response.status == "success") {
          const locations = response.data;

          return locations;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.retrieveLocation](context, locationId) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventLocationController].apiEventLocationsIdGet(locationId);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const location = response.data;

          return location;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.deleteLocation](context, locationId) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventLocationController].apiEventLocationsIdDelete(locationId);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const location = response.data;

          return location;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.createLocation](context, location) {
      console.log(location)
      try {
        const axiosResponse = await context.getters[ApiGetters.eventLocationController].apiEventLocationsPost(location);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const location = response.data;

          return location;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.updateLocationImage](context, { id, formData }) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventLocationController].apiEventLocationsIdImagePut(id, {
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        const response = axiosResponse.data;
        if (response.status == "success") {
          const location = response.data;

          return location;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.updateLocation](context, location) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventLocationController].apiEventLocationsIdPut(location.id, location);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const location = response.data;

          return location;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.updateLocationOpeningHours](context, { id, openingHoursPut }) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventLocationController].apiEventLocationsIdOpeningHoursPut(id, openingHoursPut);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const location = response.data;

          return location;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
  },
};

export default eventsModule;