import ApiRequestError from '../utils/errors/ApiRequestError';
import ResponseFailedError from '../utils/errors/ResponseFailedError';
import { Getters as ApiGetters } from './apiStore';

const Mutations = {
  setInstruments: "setInstruments",
}

export const Actions = {
  retrieveInstruments: "retrieveInstruments",
  retrieveInstrument: "retrieveInstrument",
  deleteInstrument: "deleteInstrument",
  createInstrument: "createInstrument",
  updateInstrument: "updateInstrument",
};

// Create a new store instance.
const instrumentsModule = {
  state () {
    return {
      instruments: []
    }
  },
  mutations: {
    [Mutations.setInstruments](state, instruments) {
      state.instruments = instruments;
    },
  },
  actions: {
    async [Actions.retrieveInstruments](context, filters = {}) {
      try {
        const axiosResponse = await context.getters[ApiGetters.instrumentController].apiInstrumentsGet(
          filters.pageSize, 
          filters.pageNumber, 
          filters.sortBy, 
          filters.sortOrder,
        );

        const response = axiosResponse.data;
        if (response.status == "success") {
          const instruments = response.data;
          
          context.commit(Mutations.setInstruments, instruments);

          return instruments;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.retrieveInstrument](context, id) {
      try {
        const axiosResponse = await context.getters[ApiGetters.instrumentController].apiInstrumentsIdGet(id);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const instrument = response.data;

          return instrument;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.deleteInstrument](context, id) {
      try {
        console.log(context.getters[ApiGetters.instrumentController]);
        const axiosResponse = await context.getters[ApiGetters.instrumentController].apiInstrumentsIdDelete(id);
  
        const response = axiosResponse.data;
        if (response.status == "success") {
          const Instrument = response.data;
  
          return Instrument;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.createInstrument](context, instrument) {
      try {
        const axiosResponse = await context.getters[ApiGetters.instrumentController].apiInstrumentsPost(instrument);
  
        const response = axiosResponse.data;
        if (response.status == "success") {
          return response.data;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.updateInstrument](context, instrument) {
      try {
        const axiosResponse = await context.getters[ApiGetters.instrumentController].apiInstrumentsIdPut(instrument.id, instrument);
  
        const response = axiosResponse.data;
        if (response.status == "success") {
          const Instrument = response.data;
  
          return Instrument;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
  }
};

export default instrumentsModule;