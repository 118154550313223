<template>
  <nav
    class="navbar navbar-expand-lg navbar-light justify-content-between"
    style="background-color: #e3f2fd"
  >
    <router-link :to="{ name: $routes.homePage.name }" class="navbar-brand">
      Banda App
    </router-link>
    Welcome {{ loggedInUser.name }} {{ loggedInUser.surname }} ({{
      loggedInUser.email
    }})
    <div>
      <button class="btn btn-danger" @click="logOut">Log out</button>
    </div>
  </nav>
</template>
<script>
import { Actions, Getters } from "../stores/store";
export default {
  data() {
    return {
      loggedInUser: {},
    };
  },
  created() {
    this.getLoggedInUser();
  },
  methods: {
    async getLoggedInUser() {
      this.loggedInUser = await this.$store.getters[Getters.storage.user];
    },
    async logOut() {
      await this.$store.dispatch(Actions.global.logout);
      this.$router.replace({name: this.$routes.login.name});
    },
  },
};
</script>