import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/glyphicons.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Vue from 'vue';
import vTitle from 'vuejs-title'

import store from './stores/store';
import router from './router';
import App from './App.vue';

Vue.config.productionTip = false;

Vue.use(vTitle, {
  maxWidth: "90vw",
  maxHeight: "auto",
});

new Vue({
  router,
  render: h => h(App),
  store,
}).$mount('#app')

import 'bootstrap/dist/js/bootstrap.js';
